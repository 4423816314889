import React from "react";
import PropTypes from "prop-types";

import "../../styles/normalize.css";
import "../../styles/global.css";
import "../../styles/legionarios.css";

const Layout = ({ children }) => (<main>{children}</main>);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
