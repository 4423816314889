import React from "react"
import styled from "styled-components"

import school from "../../images/Legionarios/icons/semper-altius.svg"
import kinedu from "../../images/Legionarios/icons/powered-by-kinedu.svg"
import facebook from "../../images/Legionarios/icons/facebook.svg"
import insta from "../../images/Legionarios/icons/insta.svg"
import youtube from "../../images/Legionarios/icons/youtube.svg"
import spotify from "../../images/Legionarios/icons/spotify.svg"
import google from "../../images/Legionarios/icons/google.svg"
import apple from "../../images/Legionarios/icons/apple.svg"
import background from "../../images/Legionarios/backgrounds/background05.svg"

const StyledFooter = styled.section.attrs(() => ({
  className: "footer",
}))`
  background-color: #2d3153;
  padding: 2.5rem 4rem;

  @media screen and (min-width: 900px) {
    background-color: transparent;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;

    position: relative;
    padding: 5rem 6rem 5rem 3rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 5rem 6rem;
  }
`

const Grid = styled.footer.attrs(() => ({
  className: "footer-grid",
}))`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2rem;

  color: #ffffff;

  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 6rem;
    grid-row-gap: 2rem;

    align-items: center;
    justify-items: center;

    padding-left: 10rem;
    padding-right: 10rem;

    margin: 0 auto;
    max-width: 120rem;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  @media screen and (min-width: 1280px) {
    padding-left: 12rem;
    padding-right: 22.5rem;
  }
`;

const Paragraph = styled.p.attrs(() => ({
  className: "footer-p",
}))`
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 1rem;
`

const Link = styled.a.attrs(() => ({
  className: "footer-a",
}))`
  color: #ffffff;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  text-decoration: underline;

  &:hover {
    color: #ffffff;
    font-weight: 700;
    text-decoration: underline;
  }
`

const LogoContainer = styled.div.attrs(() => ({
  className: "footer-logos",
}))`
  grid-row: 1 / 2;

  text-align: center;
  margin-bottom: 4rem;

  & img {
    display: block;
    margin: 0 auto;
  }

  & img:first-child {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 900px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    margin-top: 6rem;
    margin-bottom: 1rem;

    justify-self: start;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const SocialsContainer = styled.div.attrs(() => ({
  className: "footer-socials",
}))`
  grid-row: 3 / 4;

  margin-bottom: 4rem;

  @media screen and (min-width: 900px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;

    width: 22rem;
    align-self: end;
    justify-self: end;

    margin-bottom: 0;
  }
`

const SocialsIcons = styled.div.attrs(() => ({
  className: "footer-icons",
}))`
  display: flex;

  margin-top: 1.5rem;
`

const SocialsLink = styled.a.attrs(() => ({
  className: "footer-icons-link",
}))`
  height: 4rem;
  width: 4rem;

  margin-right: 1rem;

  &:hover {
    transform: translate(2px, -2px);
  }
`

const AppsContainer = styled.div.attrs(() => ({
  className: "footer-apps",
}))`
  grid-row: 2 / 3;

  margin-bottom: 1rem;

  @media screen and (min-width: 900px) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    width: 22rem;

    align-self: end;
    justify-self: end;
  }
`

const ButtonContainer = styled.div.attrs(() => ({
  className: "footer-apps-buttons",
}))`
  justify-content: flex-start;
  display: flex;
`

const Copyright = styled.div.attrs(() => ({
  className: "footer-copyright",
}))`
  grid-row: 4 / 5;

  @media screen and (min-width: 900px) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    align-self: end;
    justify-self: start;
  }
`

const Google = styled.img.attrs(() => ({
  className: "google",
}))`
  margin-right: 1rem;
  width: 14rem;

  @media screen and (min-width: 900px) {
    margin-right: 1rem;
    width: 10.5rem;

    align-self: start;
  }
`

const Apple = styled.img.attrs(() => ({
  className: "apple",
}))`
  width: 14rem;

  @media screen and (min-width: 900px) {
    width: 10.5rem;

    align-self: start;
  }
`

const SchoolLogo = styled.img.attrs(() => ({
  className: "foooter-school-logo",
}))`
  width: 28rem;
`

const KineduLogo = styled.img.attrs(() => ({
  className: "foooter-kinedu-logo",
}))`
  width: 20rem;
`

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Grid>
        <LogoContainer>
          <SchoolLogo src={school} alt="Semper Altius" />
          <KineduLogo src={kinedu} alt="Powered by Kinedu" />
        </LogoContainer>
        <SocialsContainer>
          <Paragraph>SÍGUENOS EN:</Paragraph>
          <SocialsIcons>
            <SocialsLink
              href="https://www.facebook.com/semperaltius"
              rel="noopener"
              target="_blank"
            >
              <img src={facebook} alt="Facebook" />
            </SocialsLink>
            <SocialsLink
              href="https://www.instagram.com/redsemperaltius"
              rel="noopener"
              target="_blank"
            >
              <img src={insta} alt="Instagram" />
            </SocialsLink>
            <SocialsLink
              href="https://www.youtube.com/reddecolegiossemperaltius"
              rel="noopener"
              target="_blank"
            >
              <img src={youtube} alt="YouTube" />
            </SocialsLink>
            <SocialsLink
              href="https://open.spotify.com/show/2myJE3l1oAkyCcWiwbtIYG"
              rel="noopener"
              target="_blank"
            >
              <img src={spotify} alt="Spotify" />
            </SocialsLink>
          </SocialsIcons>
        </SocialsContainer>
        <AppsContainer>
          <Paragraph>PRÓXIMAMENTE EN:</Paragraph>
          <ButtonContainer>
            <Google src={google} alt="Google Play Store" />
            <Apple src={apple} alt="App Store" />
          </ButtonContainer>
        </AppsContainer>
        <Copyright>
          <Paragraph>Kinedu {currentYear}. All rights reserved</Paragraph>
          <Paragraph>
            <Link
              href="https://blog.kinedu.com/privacy-policy/"
              rel="noopener"
              target="_blank"
            >
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link
              href="https://www.kinedu.com/privacy-policy"
              rel="noopener"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </Paragraph>
        </Copyright>
      </Grid>
    </StyledFooter>
  )
}

export default Footer
