import React from "react"
import styled from "styled-components"

import bgTopMobile from "../../images/Legionarios/backgrounds/background-top04.svg"
import bgTopDesktop from "../../images/Legionarios/backgrounds/background-top02.svg"
import bgBottomDesktop from "../../images/Legionarios/backgrounds/background-bottom02.svg"

import webp from "../../images/Legionarios/images/catalog.webp"
import png from "../../images/Legionarios/images/catalog.png"

import greenSpiral from "../../images/Legionarios/icons/green-spiral.svg"
import blueCircle from "../../images/Legionarios/icons/blue-circle.svg"

const StyledSection = styled.section.attrs(() => ({
  className: "catalog",
}))`
  background-color: #E9F4F9;
  position: relative;
  margin-top: 11rem;

  &:before {
    background-image: url(${bgTopMobile});
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 11rem;
    position: absolute;
        left: 0;
        top: -11rem;
    width: 100%;
    z-index: -1;
  }

  @media screen and (min-width: 900px) {
    background-color: #E9F4F9;
    position: relative;
    margin-top: 10.7rem;
    margin-bottom: 18rem;

    &:before {
      background-image: url(${bgTopDesktop});
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 10.7rem;
      position: absolute;
          left: 0;
          top: -10.7rem;
      width: 100%;
      z-index: -1;
    }

    &:after {
      background-image: url(${bgBottomDesktop});
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 13rem;
      position: absolute;
          left: 0;
          bottom: -13rem;
      width: 100%;
      z-index: -1;
    }
  }
`

const Grid = styled.footer.attrs(() => ({
  className: "catalog-grid",
}))`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 3rem;

  @media screen and (min-width: 900px) {
    margin: 0 auto;
    max-width: 120rem;

    grid-template-columns: 1fr 1.75fr;
    grid-template-rows: auto;
    grid-gap: 4rem;
  }
`;

const Text = styled.div.attrs(() => ({
  className: "catalog-text",
}))`
  position: relative;

  @media screen and (min-width: 900px) {
    padding-top: 0;
  }
`

const Paragraph = styled.p.attrs(() => ({
  className: "catalog-p",
}))`
  font-size: 2rem;
  line-height: 2.6rem;
  margin-bottom: 0;
`

const Img = styled.div.attrs(() => ({
  className: "catalog-img",
}))`
  box-shadow: rgb(117, 196, 223) 10px 20px 30px -10px;
  margin-bottom: 9.5rem;
  position: relative;

  @media screen and (min-width: 500px) {
    justify-self: center;
  }

  @media screen and (min-width: 900px) {
    max-height: 42rem;
    max-width: 67rem;
    width: 100%;

    align-self: center;
    justify-self: center;
    margin-bottom: 0;
  }
`

const GreenSpiral = styled.div.attrs(() => ({
  className: "green-spiral-2",
}))`
  height: 5rem;
  position: absolute;
      top: -6rem;
      right: 2rem;
  transform: rotate(120deg);

  @media screen and (min-width: 900px) {
    top: 50rem;
    left: 36rem;
  }

  @media screen and (min-width: 1280px) {
    height: 7.5rem;
  }
`

const BlueCircle = styled.div.attrs(() => ({
  className: "blue-circle-3",
}))`
  height: 4rem;
  width: 4rem;
  position: absolute;
    bottom: -8rem;
    left: 2rem;

  @media screen and (min-width: 900px) {
    bottom: -9rem;
    left: -35rem;
  }

  @media screen and (min-width: 1280px) {
    height: 6.5rem;
    width: 6.5rem;
  }
`

const Catalog = () => {
  return (
    <StyledSection>
      <Grid>
        <Text>
          <GreenSpiral>
            <img src={greenSpiral} alt="Decoration" />
          </GreenSpiral>
          <h2>
            ¡Te apoyamos en
            <br />
            este proceso!
          </h2>
          <Paragraph>
            Accede a una biblioteca con miles de recursos, creados por expertos, para complementar las actividades de tu planeación según las necesidades del grupo o de cada alumno. <br />
            Mide el avance de cada alumno para poder acompañarlo en su proceso de aprendizaje.
          </Paragraph>
        </Text>
        <Img>
          <picture>
            <source srcset={webp} type="image/webp" />
            <source srcset={png} type="image/png" />
            <img src={png} alt="Catalog Preview" />
          </picture>
          <BlueCircle>
            <img src={blueCircle} alt="Decoration" />
          </BlueCircle>
        </Img>
      </Grid>
    </StyledSection>
  )
}

export default Catalog
