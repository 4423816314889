import { KineduPalette } from 'kinedu-react-components';
import { css } from 'styled-components';

export const Rounded = {
  container: css`
    margin: auto;
  `,
  input: css`
    color: ${KineduPalette.shadeDarker};
    background: white;
    border: 1px solid ${KineduPalette.shadeMedium};
    border-radius: 20px;
    box-shadow: none;

    width: 100%;
    height: 40px;
    padding: 0 20px;
    box-sizing: border-box;

    font-size: 16px;
    text-align: left;
    letter-spacing: 0;

    transition: all .20s ease-in-out;
    outline: none;

    &:focus {
      color: ${KineduPalette.shadeDark};
      border: 1px solid ${KineduPalette.physicalLight30};
    }
    `,
  placeholder: css`
    color: ${KineduPalette.shadeMediumDark};
  `,
  label: css`
    display: block;
    margin: 10px;

    color: ${KineduPalette.shadeDark};
    text-transform: uppercase;
    text-align: left;

    font-size: 20px;
    font-weight: 400;
    letter-spacing: 5px;
`,
};