import React, { useReducer } from 'react';
import styled from 'styled-components';
import { Texter } from 'kinedu-react-components';
import { Rounded } from '../../styles/themes/TexterRounded';
import useAuth from '../../hooks/useAuth';
import { t } from '../../language/config';

import MainButton from '../MainButton';

import bgBottomDesktop from '../../images/Legionarios/backgrounds/background-bottom01.svg';
import bgBottomMobile from '../../images/Legionarios/backgrounds/background-bottom03.svg';
import greenTriangle from '../../images/Legionarios/icons/green-triangle.svg';
import blueCircle from '../../images/Legionarios/icons/blue-circle.svg';
import webp from '../../images/Legionarios/images/girl-reading.webp';
import png from '../../images/Legionarios/images/girl-reading.png';

const HeroWrapper = styled.section.attrs(() => ({
  className: 'login',
}))`
  background-color: #E9F4F9;
  position: relative;
  margin-bottom: 6rem;

  &:after {
    background-image: url(${bgBottomMobile});
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    display: block;
    height: 6rem;
    position: absolute;
        left: 0;
        bottom: -6rem;
    width: 100%;
    z-index: -1;
  }

  @media screen and (min-width: 900px) {
    background-color: #E9F4F9;
    position: relative;
    margin-bottom: 12.8rem;

    &:after {
      background-image: url(${bgBottomDesktop});
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 12.8rem;
      position: absolute;
        left: 0;
        bottom: -12.8rem;
      width: 100%;
      z-index: -1;
    }
  }
`;

const Grid = styled.div.attrs(() => ({
  className: 'login-grid',
}))`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 2.5rem;

  @media screen and (min-width: 900px) {
    margin: 0 auto;
    max-width: 120rem;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;

    justify-content: center;
  }
`;

const Paragraph = styled.p.attrs(() => ({
  className: 'login-p',
}))`
  position: relative;
  width: 80%;

  @media screen and (min-width: 1280px) {
    font-size: 2.4rem;
    line-height: 3rem;
    margin-bottom: 6rem;
  }
`;

const AuthBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Separator = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 20px;
  }
`;

const StyledTexter = styled(Texter).attrs(() => ({
  className: 'login-texter',
}))`
  @media screen and (min-width: 500px) {
    width: 30rem;
  }
`;

const StyledSubmit = styled(MainButton).attrs(() => ({
  className: 'login-button',
}))`
  @media screen and (min-width: 500px) {
    width: 30rem;
  }
`;

const Text = styled.div.attrs(() => ({
  className: 'login-text',
}))``;

const Img = styled.div.attrs(() => ({
  className: 'login-img',
}))`
  max-height: 31.4rem;
  width: 100%;
  margin-bottom: 4rem;
  position: relative;

  @media screen and (min-width: 500px) {
    justify-self: center;
    width: 42rem;
  }

  @media screen and (min-width: 900px) {
    max-height: 52rem;
    max-width: 56rem;

    align-self: center;
    justify-self: center;

    margin-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    width: 48rem;
  }

  @media screen and (min-width: 1280px) {
    height: 52rem;
    width: 56rem;
  }
`;

const ErrorMessage = styled.p`
  color: #ff0039;
  font-size: 15px;
  padding: 5px 10px;
  margin: -15px 0px 10px;
  text-transform: initial;
  border-radius: 100px;

  &::first-letter {
    text-transform: uppercase;
  }

  @media screen and (min-width: 500px) {
    width: 30rem;
  }
`;

const GreenTriangle = styled.div.attrs(() => ({
  className: 'green-triangle-1',
}))`
  height: 4rem;
  width: 4rem;
  position: absolute;
    top: 10rem;
    right: 2.5rem;

  @media screen and (min-width: 900px) {
    top: 10rem;
    left: 65rem;
  }

  @media screen and (min-width: 1280px) {
    height: 6.5rem;
    width: 6.5rem;
  }
`;

const BlueCircle = styled.div.attrs(() => ({
  className: 'blue-circle-1',
}))`
  height: 4rem;
  width: 4rem;
  position: absolute;
    right: 0;

  @media screen and (min-width: 500px) {
    display: none;
  }

  @media screen and (min-width: 900px) {
    display: block;
  }

  @media screen and (min-width: 1280px) {
    height: 6.5rem;
    width: 6.5rem;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INPUT_VALUE':
      return { ...state, [action.name]: action.value };
    default:
      throw new Error();
  }
};

const initialState = {
  email: '',
  password: '',
};

const Hero = ({ lang }) => {
  const [data, dispatch] = useReducer(reducer, initialState);
  const { onLogin, errors, setErrors } = useAuth();

  const handleOnAuth = () => {
    setErrors({
      ...errors,
      emailError: data.email === '' ? 'Email is required' : '',
      passError: data.password === '' ? 'Password is required' : '',
    });
    if (data.email === '' || data.password === '') return;
    onLogin(data);
  };

  const handleOnChange = (name, value) => {
    dispatch({ type: 'SET_INPUT_VALUE', name, value });
  };

  console.log('%c🐛 errors', 'background: #332167; color: #b3d1f6; font-size: 11px', errors);

  return (
    <HeroWrapper>
      <Grid>
        <Text>
          <h2>¡Estamos contigo!</h2>
          <Paragraph>
            Apóyate de recursos creados por otros expertos como tú para
            optimizar tu tiempo y seguir impulsando el potencial de tus alumnos,
            sin importar si se encuentran en casa o en el colegio.
            {/* <GreenTriangle>
              <img src={greenTriangle} alt="Decoration" />
            </GreenTriangle> */}
          </Paragraph>
          <AuthBox>
            <Separator>
              <StyledTexter
                type="email"
                theme={Rounded}
                placeholder={t('Correo')}
                onChange={value => handleOnChange('email', value)}
              />
              {errors.emailError && (
                <ErrorMessage>{t(errors.emailError)}</ErrorMessage>
              )}
              <StyledTexter
                type="password"
                theme={Rounded}
                placeholder={t('Contraseña')}
                onChange={value => handleOnChange('password', value)}
              />
              {(errors.passError || errors.generalError) && (
                <ErrorMessage>{t(errors.passError || errors.generalError)}</ErrorMessage>
              )}
              <StyledSubmit fill onClick={handleOnAuth}>
                {t('Iniciar sesión')}
              </StyledSubmit>
            </Separator>
          </AuthBox>
        </Text>
        <Img>
          <picture>
            <source srcSet={webp} type="image/webp" />
            <source srcSet={png} type="image/png" />
            <img src={png} alt="Girl Reading" />
          </picture>
          <BlueCircle>
            <img src={blueCircle} alt="Decoration" />
          </BlueCircle>
        </Img>
      </Grid>
    </HeroWrapper>
  );
};

export default Hero;
