import React from "react"
import styled from "styled-components"

import webp from "../../images/Legionarios/images/girl-tablet.webp"
import png from "../../images/Legionarios/images/girl-tablet.png"

import greenSpiral from "../../images/Legionarios/icons/green-spiral.svg"
import yellowMoon from "../../images/Legionarios/icons/yellow-moon.svg"
import blueCircle from "../../images/Legionarios/icons/blue-circle.svg"

const Grid = styled.footer.attrs(() => ({
  className: "platform-grid",
}))`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 3rem;

  @media screen and (min-width: 900px) {
    margin: 0 auto;
    max-width: 120rem;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 6rem;

    justify-content: center;
  }
`

const Text = styled.div.attrs(() => ({
  className: "platform-text",
}))`
  position: relative;

  @media screen and (min-width: 900px) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
`

const Paragraph = styled.p.attrs(() => ({
  className: "platform-p",
}))`
  color: #134f7d;
  font-weight: bold;
  margin-left: 2rem;

  @media screen and (min-width: 1024px) {
    font-size: 2rem;
  }
`

const List = styled.ul.attrs(() => ({
  className: "platform-ul",
}))`
  list-style-type: disc;
  padding-left: 2rem;
`

const ListItem = styled.li.attrs(() => ({
  className: "platform-li",
}))`
  color: #134f7d;
  margin-bottom: 0;

  @media screen and (min-width: 1024px) {
    font-size: 2rem;
  }
`

const Img = styled.div.attrs(() => ({
  className: "platform-img",
}))`
  position: relative;

  @media screen and (min-width: 500px) {
    justify-self: center;
    width: 42rem;
  }

  @media screen and (min-width: 900px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    width: 100%;
    max-height: 49rem;
    max-width: 58rem;
  }
`

const GreenSpiral = styled.div.attrs(() => ({
  className: "green-spiral-1",
}))`
  height: 5rem;
  position: absolute;
  top: -5rem;
  right: 9rem;
  transform: rotate(120deg);

  @media screen and (min-width: 900px) {
    top: -7rem;
    left: -70rem;
  }

  @media screen and (min-width: 1280px) {
    height: 7.5rem;
    width: 7.5rem;
  }
`

const YellowMoon = styled.div.attrs(() => ({
  className: "yellow-moon-1",
}))`
  width: 4rem;
  position: absolute;
  top: 4rem;
  right: 0;

  @media screen and (min-width: 900px) {
    top: -8rem;
    right: 40rem;
  }

  @media screen and (min-width: 1280px) {
    width: 5.5rem;
  }
`

const BlueCircle = styled.div.attrs(() => ({
  className: "blue-circle-2",
}))`
  height: 4rem;
  width: 4rem;
  position: absolute;
  left: -1rem;

  @media screen and (min-width: 1280px) {
    height: 6.5rem;
    width: 6.5rem;
  }
`

const Platform = () => {
  return (
    <section>
      <Grid>
        <Text>
          <GreenSpiral>
            <img src={greenSpiral} alt="Decoration" />
          </GreenSpiral>
          <YellowMoon>
            <img src={yellowMoon} alt="Decoration" />
          </YellowMoon>
          <h2>
            ¡Todo en un
            <br />
            mismo lugar!
          </h2>
          <Paragraph>Una plataforma intuitiva que te permite:</Paragraph>
          <List>
            <ListItem>
              Planear las actividades de tus alumnos por día y semana, así como
              enviar la planeación a sus papás.
            </ListItem>
            <ListItem>
              Mostrar en el calendario las sesiones en vivo que tendrás ya sea
              invididuales o grupales y llevar una agenda.
            </ListItem>
            <ListItem>
              Enriquecer la oferta que das a tus alumnos con contenidos
              institucionales de acuerdo a las necesidades de tu grupo o tus
              alumnos.
            </ListItem>
            <ListItem>
              Tener una buena comunicación y retroalimentación con los padres de
              familia, todo en un mismo lugar, permitiéndote dar un seguimiento
              cercano a cada alumno.
            </ListItem>
          </List>
        </Text>
        <Img>
          <picture>
            <source srcset={webp} type="image/webp" />
            <source srcset={png} type="image/png" />
            <img src={png} alt="Girl with Tablet" />
          </picture>
          <BlueCircle>
            <img src={blueCircle} alt="Decoration" />
          </BlueCircle>
        </Img>
      </Grid>
    </section>
  )
}

export default Platform
