import React from "react"
import styled from "styled-components"

import semperaltius from '../../images/Legionarios/icons/site-logo.svg';

const Header = styled.div.attrs(() => ({
    className: "nav"
}))`
    background: #ffffff;
    height: 10rem;
    width: 100%;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media screen and (min-width: 900px) {
      height: 14rem;
      justify-content: flex-start;
    }
`;
  
const NavLogo = styled.div.attrs(() => ({
    className: "nav-logo"
}))`
    height: 5rem;
    width: 22rem;
  
    @media screen and (min-width: 500px) {
      height: 8rem;
      width: 30rem;
    }
  
    @media screen and (min-width: 900px) {
      margin-left: 2.5rem;
      height: 10rem;
      width: 44rem;
    }
  
    @media screen and (min-width: 1024px) {
      margin-left: 4.5rem;
    }
  
    @media screen and (min-width: 1280px) {
      margin-left: 4.5rem;
    }
`

const Nav = () => {
  return (
    <Header>
      <NavLogo>
        <img src={semperaltius} alt="Semper Altius Logo" />
      </NavLogo>
    </Header>
  )
}

export default Nav
