import React, { useState } from 'react';
import Layout from '../components/Legionarios/Layout';
import SEO from '../components/seo';

import { windowGlobal } from '../config';
import { WithTrans } from '../language/withTrans';
import AuthView from '../views/AuthView';
import ForgotPasswordView from '../views/ForgotPasswordView';
import EmailSentView from '../views/EmailSentView';
import metaTranslations from '../lib/utils';

import Nav from '../components/Legionarios/Nav';
import Hero from '../components/Legionarios/Hero';
import Platform from '../components/Legionarios/Platform';
import Catalog from '../components/Legionarios/Catalog';
import Footer from '../components/Legionarios/Footer';

const samperaltius = ({ lang }) => {
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].legTitle}
        description={metaTranslations[lang].legDescription}
        meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
      />
      <Nav />
      <Hero lang={lang} />
      <Platform />
      <Catalog />
      <Footer />
    </Layout>
  );
};

export default WithTrans()(samperaltius);
