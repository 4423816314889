import { Button } from 'kinedu-react-components';
import styled from 'styled-components';

const MainButton = styled(Button).attrs(() => ({
  color: 'primary',
  small: true,
  flat: true,
  rounded: true,
}))`
  font-size: 18px;
`;

export default MainButton;
